
/* ------------  General Site Responsive ----------- */
@media all and (min-width: 1201px)  {
	#MobileNavigation{
		display: none !important;
	}
}
@media all and (max-width: 1200px)  {

	#SideNav{
		display: none !important;
	}
	#MenuIcon{
		display: block !important;
	}
	#Menu{
		display: none !important;
	}
	.topLinks{
		display: none;
	}
	.homebtn{
		display: none;
	}
	#header .MainLogo {
    	margin-top: 10px;
    	margin-bottom: 10px;
    	font-size: 1.7em;
    	opacity: 1 !important;
	}
	.scrollNav #header {
	    -webkit-transform: translateY( 0px);
	    -moz-transform: translateY( 0px);
	    -ms-transform: translateY( 0px);
	    transform: translateY( 0px);
	}
	.twitterBanner {
    	height: calc(100% - 63px);
    	display: none !important;
	}
	.bannerText{
		width: 100%;
		*{
			text-align: center !important;
		}
	}
	#breadcrumbs {
	    margin-top: 62px;
	}
}

@media all and (max-width: 786px)  {
	.tacMid{
		text-align: center !important;
	}
	.clearMid{
		width: 100%;
		padding: 0px !important;
		clear: both;
	}
}
/*** Newsletters Page Responsive ***/
@media all and (max-width: 726px)  {
	.NewsletterItem{
		width: 50% !important;
	}
}
@media all and (max-width: 500px)  {
    .NewsletterItem{
		width: 100% !important;
	}
}
/*** Newsletters Page Responsive ***/

/*** Staff Responsive ***/
@media all and (max-width: 520px)  {
	.StaffPage .staffProfile .profilePic {
	    margin: 0 0px 30px 0 !important;
	    float: left;
	    width: 100%;
	    clear: both;
	    display: block;
	}
}
/*** Staff Responsive ***/
/*** Gallery Responsive ***/

@media all and (max-width: 800px)  {
    .galleryImage{
        width: 50% !important;
        padding-bottom: 50% !important;
    }
}
@media all and (max-width: 768px)  {
	.grid-item, .grid-sizer { 
		width: 50% !important; 
	}
}
@media all and (max-width: 500px)  {
    .galleryImage{
        width: 100% !important;
        padding-bottom: 100% !important;
    }
}

@media all and (max-width: 450px)  {
	.grid-item, .grid-sizer { 
		width: 100% !important; 
	}
}
/*** Gallery Responsive ***/

/* ------------  General Site Responsive ----------- */


@media all and (max-width: 1370px)  {
	.homebtn {
   		left: 10px;
	}
}

@media all and (max-width: 1230px)  {
	.overlay1{
		display: none !important;
	}
	#Home3{
		.col-3,
		.col-9{
			width: 100%;
			clear: both;
			text-align: center;
		}
		.WelcomeImage{
		    max-width: 100%;
		    width: 300px;
		    padding-bottom: 0px;
		    min-width: 0px;
		    height: 300px;
		    float: none;
		    margin: 0px auto;
		}
	}
	#Home4{
		.col-2{
			width: 33.33%;
		}
	}
}
@media all and (max-width: 1100px)  {
	footer{
			text-align: center !important;
		*{
			text-align: center !important;
		}
		.col-6{
			width: 100% !important;
		}
		.up{
			display: none;
		}
	}
}
@media all and (max-width: 1030px)  {
	.sideBarContainer,
	.sideBarLeft{
		display: none;
	}
	.mainPageContent{
		width: 100% !important;
	}
}
@media all and (max-width: 730px)  {
	
	.InnerPage{
		#HousePointContainer{
		.col-2{
			width: 50%;
		}
		}
	}
}
@media all and (max-width: 450px)  {
	
	.InnerPage{
		#HousePointContainer{
		.col-2{
			width: 100%;
		}
		}
	}
}
@media all and (max-width: 980px)  {
	.PageImage{
		width: 50% !important; 
		padding-bottom: 30% !important;
	}
	.PageImage:first-of-type,
	.PageImage:last-of-type{
			display: none !important;
	}
}
@media all and (max-width: 750px)  {
	.quicklink{
		width: 50% !important;
		&:after{
			display: none !important;
		}
	}
	.InnerPageTitle {
    	font-size: 2em;
    }
	.bannerText {
    	font-size: 2em;
    	img{
    		height: 20px;
    	}
	}

	#Home4{
		.col-2{
			width: 50%;
		}
	}
}

@media all and (max-width: 1337px)  {
	.footerInner .footerLinks a {
    	padding: 5px;
    	font-size: 0.7em;
    }
}
@media all and (max-width: 500px)  {
	#header .MainLogo {
		margin: 20px 0px !important;
    	font-size: 1em;
    }
	.quicklink{
		width: 100% !important; 
	}

	.InnerPageTitle {
    	font-size: 1.5em;
    }
	.bannerText {
    	font-size: 1.4em;
	}

	#Home4{
		.col-2{
			width: 100%;
		}
	}
}
