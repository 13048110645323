/* -------------------- General Site layouts -------------------- **/
*{
	font-family: $font-stack;
	color: $textDark;
	text-decoration: none !important;
	outline: none !important;
	-webkit-font-smoothing: antialiased;
}

body{
	overflow-y: scroll;
	overflow-x: hidden;
	background-color: #fff;
}

img{
	max-width: 100%;
}


/*** Page ***/
.PageResults,
.InnerPage{
	.banner{
		height: 66vh;
	}
}

.vacancy{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	display: block;
	float: left;
	width: 100%;
}



.CalendarFilter{
	padding: 10px;
}

/* .CalendarFilter{
	span{
		display: inline-block;
		padding: 5px 0px;
		margin: 0px 5px;
		cursor: pointer;
	}
} */
.CalendarEvent #allEvents ul li,
.Calendar #allEvents ul li{
/* 	display: none; */
}
/* .CalendarEvent.All #allEvents ul li,
.Calendar.All #allEvents ul li{
	display: block;
} */
.CalendarFilter span{
	cursor: pointer;
	display: inline-block;
	padding: 5px;
	border-bottom: 2px solid transparent;
	margin-bottom: 10px;
}
.CalendarFilter span.active{
	border-bottom: 2px solid $themeColor !important;
	color: $themeColor !important;
}
.noEvents{
	display: none;
	padding: 20px 10px;
	color: #666;
	@include border-radius(3px);
    background-color: #eee;
}

#allEvents ul{
	max-width: 500px;
	margin: 0px !important;
	padding: 0px !important;
}

#allEvents ul li{
	margin-bottom: 10px;
	background-color: $themeColor;
	float: left;
    width: 100%;
    clear: both;
    @include transition(background 0.35s);
    display: block;
}






.toggleContent{
	display: none;
	font-size: 0.8em;
	*{
		font-size: 1em;
		color: #333 !important;
	}
	.url{
		padding: 10px;
    	color: #fff !important;
    	background-color: $themeColor;
    	@include transition($t1);
    	@include border-radius(3px);
    	/*&:hover{
    		background-color: #5a5962 !important;
    		color: #fff !important;
    	}*/
	}
}


.eventTitle{
	color: #333 !important;
	padding: 10px;
	text-transform: uppercase;
}
.toggleBtn{
	cursor: pointer;
	sup{
	display: none !important;
	}
}

.calendar-widget-table tbody .calendar-header td{
	
	    background-color: $themeColor;
}

.news-teaser{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	display: block;
	float: left;
	width: 100%;

	h3{
  		color: $themeColor;
  		font-size: 1.5em;
	}
	img{
		@include border-radius(50%);
	}
}



.noticesBG{
	position: fixed;
	display: none;
	top: 0px;
	left: 0px;
	z-index: 9999;
	height: 100%;
	width: 100%;
	background-color: rgba(0,0,0, 0.75);
}
.noticesContainer{
	position: fixed;
	z-index: 9999;
	top: 50%;
	display: none; 
	left: 50%;
	@include transform($center);
	padding: 30px;
	background-color: #e8f5fb !important;    
	max-width: 100%;
	width: 500px;
}
.NoticeTitle{
	text-transform: uppercase;
	font-weight: bold;
	padding: 0px !important;
    border-bottom: none !important;
    font-size: 1.3em;
}
.readMoreLinkNotices{
    font-size: 0.8em;
    border: 2px solid #333;
    padding: 3px 5px;
    margin-top: 10px;
    display: inline-block;
}
.fullWidth{
	position: relative;
	clear: both;
	width: 100%;
	float: left;
}
.closeNotices{
	position: absolute;
	top: 10px;
	right: 10px;
	padding-right: 20px;
	color: $themeColor;
	font-size: 0.8em;
	cursor: pointer;
	z-index: 9999; 

}
.closeNotices:after {
    content: "x";
    position: absolute;
    color: #d92d2a;
    display: block;
    right: 5px;
    top: 49%;
    text-transform: uppercase;
    @include transform(translateY(-50%));
}
.noticesInner{
	max-height: 90vh;
    overflow-y: auto;
    padding: 10px;
	*{
		color: #333;
	}
	.col-12{
		padding: 20px 0px;
		border-bottom: 2px solid #cccccc;
	}

}

.MainMenuClose{
	display: none !important;
}

.mainContent{
	input[type=text],
	input[type=email],
	input[type=password],
	textarea{
	    width: 100%;
	    padding: 10px 20px;
	    background-color: #eee;
	    border: none !important;
	    margin-bottom: 10px;
	    margin-top: 5px;
	    width: 100%;
	    max-width: 100%;
	 
    	font-weight: lighter;
	}

	label{
		font-weight: lighter;
	}

	input[type=submit]{
			padding: 10px 20px !important;
		    display: block;
		    width: 100%;
		    border: none !important;
		    color: #fff !important;
		    font-weight: lighter;
		    margin-top: 10px;
		    @include transition($t1);
		    @include border-radius(3px);
		    max-width: 100%;
			background-color: #1b3080;
			&:hover{
		    	background-color: #5a5962 !important;
		    }
	}
	#ForgotPassword{
		margin-top: 20px;
		a{
			font-weight: lighter;
			color: #1d1e3c;
		}
	}
}
.addToCalendar,
.attachment,
.readMoreLink,
.button{
		padding: 10px 20px !important;
	    display: inline-block;
	    text-align: center;
	    color: #fff;
	    font-size: 0.7em !important;
	    cursor: pointer;
	    *{
	    	font-size: 1em;
	    	color: #fff;

	    @include transition($t1);
	    }
	    border: none !important;
	    color: #fff !important;
	    margin-top: 10px;
	    @include transition($t1);
	    @include border-radius(3px);
	   	background-color: $themeColor;
		&:hover{
	    	background-color: #4b4b4b !important;
	    }
}

/** Inputs  **/
#content{
	input[type=text],
	input[type=email],
	input[type=password],
	textarea{
	    width: 100%;
	    padding: 10px 20px;
	    background-color: #eee;
	    border: none !important;
	    margin-bottom: 10px;
	    margin-top: 5px;
	    width: 100%;
    	font-weight: lighter;
	}

	label{
		font-weight: lighter;
	}

	input[type=submit]{
			padding: 10px 20px !important;
		    display: block;
		    width: 100%;
		    border: none !important;
		    color: #fff !important;
		    font-weight: lighter;
		    margin-top: 10px;
		    @include transition($t1);
		    @include border-radius(3px);
		    background-color: $themeColor;
			max-width: none !important;
		    width: auto !important;
		    display: inline-block;

			&:hover{
	    	    background-color: #71142b !important;
		    }
	}
	#ForgotPassword{
		margin-top: 20px;
		a{
			font-weight: lighter;
			color: #1d1e3c;
		}
	}
}
/** Inputs  **/



.vacancy{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	display: block;
	float: left;
	width: 100%;
}



.heading-2{
	color: $themeColor !important;font-weight: lighter;
	*{color: $themeColor !important;font-size: 1em;font-weight: lighter;}
}
.archives{
	position: relative;
	display: block;
	float: left;
	width: 100%;
	clear: both;
	padding: 0px !important;
	margin: 0px 0px 30px 0px !important;

	.archive{
		position: relative;
		display: block;
		float: left;
		clear: both;
		color: #fff;
		width: 100%;
		margin: 5px 0px !important;
		cursor: pointer;
		@include transition($t1);
		img{
			position: absolute;
		    right: 10px;
		    top: 50%;
		    max-width: 17px;
			@include transform(translateY(-50%));
		}
		a{
			display: block;
			width: 100%;
			clear: both;
			float: left;
			padding: 8px 30px  8px 15px;
			top: 0px;
			left: 0px;
			width: 100%;
			z-index: 1;
			height: 100%;
		}
		*{
		@include transition($t1);
			color: #fff !important;
		}
		
		@include border-radius(3px);

	    background-color: $themeColor !important;

		 &:hover{
	    	    background-color: #454545 !important;
	    		*{
	    			color: #fff !important;
	    		}
		    }
	}
}

.SubDate{
	margin-top: 20px;
    font-size: 1.4em;
    margin-bottom: 10px;
}


.archive{
	*{
		color: #fff !important;
	}
}
/*
|===============================================================
|	Uploads
|===============================================================
*/

.upload{
	position: relative;
	display: block;
	width: 100%;
	max-width: 500px;
	clear: both;
	float: left;
	padding: 10px 45px 12px 30px;
	border: 1px solid $themeColor;
	margin: 5px 0px;
	background-color: transparent;
	@include transition($t1);
	.uploadTitle{
		text-transform: uppercase;
		font-size: 1em;
		font-weight: normal !important;
		color: $themeColor !important;
	    display: inline-block;
	}
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
	.fileIcon{


		position: absolute;
		right: 10px;
		top: 50%;
		@include transform(translateY(-50%));



	    background-color: $themeColor;
	    display: inline-block;
	    float: right;
	    width: 25px;
	    height: 30px;
	    @include transition($t1);
	    span{
	    	@include transition($t1);
	    	color: #fff !important;
		    background-color: #000;
		    height: 13px;
		    width: 100%;
		    padding-top: 2px;
		    font-size: 7px;
		    text-transform: uppercase;
		    display: block;
		    text-align: center;
		    margin-top: 8px;
	    }
	}

	&:hover{
		background-color: #e2e0df !important;
		color: #1b3080 !important;
		.uploadTitle{
			/*color: #000 !important;*/
		}
		/*.fileIcon{
	    	background-color: #000 !important;
			span{
			    color: #000 !important;
				background-color: #009e7f;
		   }
		}*/
	}
}


.upload{
	/*padding: 10px;
	border-bottom: 2px solid #eee;*/
	*{
		vertical-align: middle;
		font-weight: lighter !important;
	}
}





.galleryImage{
	display: block;
	width: 33.33%;
	position: relative;
	padding-bottom: 33.33%;

	.galleryImageInner{
		position: absolute;
		overflow: hidden;
		width: 95%;
		height: 95%;
		left: 50%;
		top: 50%;
		background-color: #2b2b2b;
		@include transform(translate(-50%,-50%));
		.gallerImageBg{
			@include transition($t1);
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-positon: center;
			left: 0px;
			top: 0px;
			opacity: 1;
		}
		.gallerImageText{
			opacity: 0;
			text-align: center;
			width: 95%;
			@include transition($t1);
			position: absolute;
			top: 50%;
			left: 50%;
			color: #fff !important;
			@include transform(translate(-50%,-50%) scale(0.5));
		}
	}
	&:hover{
		.galleryImageInner{
			.gallerImageBg{
				opacity: 0.2 !important;
			}
			.gallerImageText{
				@include transform(translate(-50%,-50%) scale(1));
				opacity: 1 !important;
			} 
		}
	}
	a{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		z-index: 2;
	}
}

/*
|===============================================================
|	Gallery 3
|===============================================================
*/

#bx_slider {
	height: 500px;
	li {
		height: 100%;
		text-align: center;
		img {
			display:inline-block;
			height: 100%;
		}
	}
}
.innerSlideGal{
	padding-bottom: 100%;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
}

.GalNext,
.GalPrev{
	cursor: pointer;
	position: absolute;
	z-index: 200;
	top: 50%;
	margin-top: 8px;
	@include transform(translateY(-50%));
	i{
		color: #fff !important;
	}
}
.GalNext{
	left: 0px;
	padding: 20px;
	background-color: rgba(0,0,0,0.9);
}
.GalPrev{
	right: 0px;
	padding: 20px;
	background-color: rgba(0,0,0,0.9);
}

.bx_pages_frame {
	position: relative;
	#bx_pages {
		margin-top: 20px;
		li {
			background-position: center center;
			cursor: pointer;
			height: 200px;
			    opacity: 0.5;
			    @include transition(opacity 0.35s);
			    &:hover{
			   	 opacity: 1 !important;
			    }
		}
	}
	.controls {
		i {
			position: absolute;
			top: 50%;
			color: $mainColor2;
			font-size: 24px;
			margin-top: -12px;
			cursor: pointer;
			@include transition(all 0.5s);
			&:hover {
				opacity: 0.5;
			}
		}
		i.prev {
			left: 0;
		}
		i.next {
			right: 0;
		}
	}
}

/*
|===============================================================
|	Masonry
|===============================================================
*/

.grid {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 700px;
	a {
		font-size: 0;
		line-height: 0;
	}
}

.grid-item, .grid-sizer { 
	position: relative;
	width: 25%; 
	float: left;
	padding: 2px;
	cursor: pointer;
	@include transition(all 0.5s);
	img {
		width: 100%;
		height: auto;
	}
}

.grid-item.hoverState {
	opacity: 0.7;
}

.grid-item--width2 { width: 400px; }


.galleryImage{
	width: 33.33%;
    float: left;
	position: relative;
	padding-bottom: 33.33%;

	.galleryImageInner{
		position: absolute;
		overflow: hidden;
		width: 95%;
		height: 95%;
		left: 50%;
		top: 50%;
		background-color: #2b2b2b;
		@include transform(translate(-50%,-50%));
		.gallerImageBg{
			@include transition($t1);
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-positon: center;
			left: 0px;
			top: 0px;
			opacity: 1;
		}
		.gallerImageText{
			opacity: 0;
			text-align: center;
			width: 95%;
			@include transition($t1);
			position: absolute;
			top: 50%;
			left: 50%;
			color: #fff !important;
			@include transform(translate(-50%,-50%) scale(0.5));
		}
	}
	&:hover{
		.galleryImageInner{
			.gallerImageBg{
				opacity: 0.2 !important;
			}
			.gallerImageText{
				@include transform(translate(-50%,-50%) scale(1));
				opacity: 1 !important;
			} 
		}
	}
	a{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		z-index: 2;
	}
}
#CustomLoginForm_LoginForm{
	max-width: 400px;
	width: 100%;
}

.staffProfile {
	.staffProfilePic {
		width:200px;
		height:200px;
		background-size: cover;
		background-position:center center;
		border-radius:20px;
	}
}
.HorizontalStack {
	.staffProfile {
		width:auto;
		margin:0 30px 30px 0;
		.profilePic {
			margin:0 !important;
		}
	}
}

/*
|===============================================================
|	Staff Page Templates
|===============================================================
*/

.StaffPage {
	.staffProfile {
		display:inline-block;
		width:100%;
		margin:30px;
		.profilePic {
			margin:0 30px 0 0;
			float:left;
			display:inline-block;
		}
		.bio {
			min-width:50%;

			p {
				display:block;
			}
		}
	}
}

.StaffPageStack {
	.staffProfile {
		text-align:center;
		margin-bottom:60px;
		.bio {
			p {
				text-align:justify;
				width:100%;
				max-width:800px;
				margin:0 auto 20px auto;
			}
		}
	}
}

.StaffPageNoPic {
	.staffProfile {
		text-align:center;
		margin-bottom:60px;
		.bio {
			p {
				text-align:justify;
				width:100%;
				max-width:800px;
				margin:0 auto 20px auto;
			}
		}
	}
}

.bio{
	h4{
		font-weight: normal;
    	font-size: 1.2em;
	}

	h3{
		text-transform: uppercase;
		font-weight: lighter;
	}
}

 .HorizontalStack .staffProfile {
    width: auto;
    margin: 0 30px 30px 0;
}

.VerticalStack{
	    margin: 0px ;
	  
	    .staffProfile {
	    	margin: 0px ;
			padding: 30px 0px;
			border-bottom: 2px solid #eee;
		}
}
.NameDepOnly{
	    margin: 0px ;
	  
	    .staffProfile {
	    	margin: 0px ;
			padding: 30px 0px;
			border-bottom: 2px solid #eee;
		}
}
.HorizontalStack{
	.profilePic{
		padding-bottom: 20px;
	}
}

.profilePic img{
	display: inline-block;
	padding-bottom: 20px;
}

.VerticalStack{
	.profilePic img{
		display: inline-block;
		padding-bottom: 0px;
	}
}


#SearchResults{ 
	padding: 0px;
	margin: 0px;
	display: block;
	float: left;
	width: 100%;
	font-size: 0.8em;
	li{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	margin: 0px;
	display: block;
	float: left;
	width: 100%;
		list-style: none !important;
	}
	.readMoreLink{
		font-size: 1em !important;
	}
}


.NewsletterItem{
	display: block;
	width: 20%;
	float: left;
	padding: 10px;
	.NewsletterInner{
		display: block;
		position: relative;
		width: 100%;
		@include border-radius(3px);
		@include transition($t1);
		border: 5px solid #fff;
		@include box-shadow(0px 0px 10px 0px #9c9c9c);
		overflow: hidden;
		.newsletterThumb{
			float: left;
			clear: both;
			background-color: #eee;
			width: 100%;
			background-image: url(../images/newsletter.png);
			background-size: 60%;
    		background-repeat: no-repeat;
			background-position: center;
			padding-bottom: 110%;
		}
		a{
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
		}
	}
	&:hover{
		.NewsletterInner{
			border: 5px solid $themeColor !important;
		}
	}
	.newsletterTitle{
		padding: 10px;
		font-size: 0.8em;
		float: left;
	    width: 100%;
	    text-align: center;
	    clear: both;
	}
}

.Newsletter{
	width: 20%;
    padding-bottom:20%;
    position: relative;
	min-width: 200px;
    min-height: 200px;
    display: inline-block;
    float: none;
}
.NewsLetterInner{
	top: 50%;
	left: 50%;
	width: 90%;
	height: 90%;
	border-radius: 3px;
	overflow: hidden;
	position: absolute; 
	border: 2px solid #d2d2d2;
    background-color: #f9f9f9;
	transition: all 0.35s;
	-o-transition: all 0.35s;
	-ms-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-webkit-transition: all 0.35s;
	transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-o-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
}



.NewsLetterInner .imgContainer{
	display: inline-block;
	position: absolute;
	left: 50%;
	top: 40%;
	transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
}
.Newsletter a{
	position: absolute; 
	width: 100%;
	height: 100%;
	z-index: 200;
	left: 0px;
    top: 0px;
}
.Newsletter:hover .NewsLetterInner{
	border: 2px solid #d2d2d2;
    background-color: #f9f9f9;
	box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-o-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-ms-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
}

.Newsletter .downloadInfo{
	position: absolute;
	transform: translateX(-50%);
	-o-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	left: 50%;
	color: #777;
	width: 98%;
	text-align: center;
	font-size: 1em;
	bottom: 10%;
	transition: all 0.35s;
	-o-transition: all 0.35s;
	-ms-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-webkit-transition: all 0.35s;
}

.Newsletter:hover .downloadInfo{
	
    color: #e42c25 !important;
}

.NewsletterTitle{
	position: relative;
	padding-bottom: 10px;
	margin-bottom: 30px;
	margin-top: 50px;
	text-align: center;
	font-size: 1.5em;
	color: #303c68 !important;
	*{
		font-weight: lighter;
		color: #303c68 !important;
	}
}

.InnerPage{
    .calPrev,
    .calNext {
        top: 37px;
    }
    .calendar-widget-table,
    .outerCalendarContainer{
        width: 100%;
    }
    .eventContainer{
        display: none !important;
    }
    .calHeader{
        .col-7,
        .col-5{
            width: 100%;
            text-align: center;
        }
        .col-5{
            display: none !important;
        }
    }
}
.group1{
	@include transition($t1);
	opacity: 0;
}
.PageLoaded{
	.group1{
		opacity: 1 !important;
	}
}
#gmap_canvas{
	min-height: 400px;
}


.SearchContainer{
    display: none;
    position: fixed;
    top: -4px;
    background-color: #dbb95a;
    padding: 7px;
    right: 0px;
    z-index: 9999;
	@include transform(translateY(100%))

	#Search{
		display: block;
		float: left;
	}

    .middleColumn,
    button,
    #SearchForm_SearchForm_Search_Holder{
    	float: left;
    	display: block;
    }

    button{
	    background-color: #dbb95a;
	    border: none !important;
	    color: #fff !important;
	    padding: 5px 10px;
	    @include border-radius(0px !important);
 

	    i{
	    	color: #fff !important;
	    }
	    float: left;
    	display: block;
    }
    input[type=submit]{
    	@include border-radius(0px !important);
        display: block;
	    border: none !important;
	    font-size: 1em;
	    text-transform: uppercase;
	    padding: 6px;
	    color: #fff !important;
	    font-style: italic;
	    background-color: #dbb95a;
    }
    input[type=text]{
	    border: 0px;
	    padding: 5px 10px 7px 10px;
	    color: #5f5e6d;
	    float: left;
    	@include border-radius(3px !important);
    	display: block;
    }
} 

.searchToggle{
	right: 69px;
	@include transition($t1);
}

.searchToggleButton{
	position: absolute;
	left: 50%;
	top: 7px;
	@include transform(translateX(-50%));
}

.searchToggle{
	position: absolute;
	z-index: 9999;
	top: 0px;
    cursor: pointer;
	right: 0px;
	height: 41px;
	width: 40px;
	text-align: center;
	padding-top: 8px;
	font-size: 1.1em;
	opacity: 1;
	*{
		color: #dbb95a;
	}
}

/** General Site layouts **/
















.CenterItems{
	display: flex;
	align-items: center;
	justify-content: center;
}

.flex{
	display: flex;
}

.slink{
	cursor: pointer;
}


.translate{
	cursor: pointer;
}
.TranslateBG{
	display: none;
	position: fixed;
	top: 0px;
	z-index: 9999;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(41,37,89,0.76);
}
#google_translate_element{
	display: none;
	position: fixed; 
	z-index: 9999;
	top: 50%;
	left: 50%;
	@include transform($center);
}

#gmap_canvas{
	min-height: 500px;
}

.SearchContainer{
    display: none;
    position: fixed;
    top: -9px;
    background-color: #3d3a61;
    padding: 7px;
    left: 20px;
    z-index: 9999;
	@include transform(translateY(100%))

	#Search{
		display: block;
		float: left;
	}

    .middleColumn, 
    button,
    #SearchForm_SearchForm_Search_Holder{
    	float: left;
    	display: block;
    }

    button{
	    background-color: #56b4b9;
	    border: none !important;
	    color: #fff !important;
	    padding: 5px 10px;


	    i{
	    	color: #fff !important;
	    }
	    float: left;
    	display: block;
    }
    input[type=submit]{
        display: block;
	    border: none !important;
	    font-size: 1em;
	    text-transform: uppercase;
	    padding: 5px;
	    color: #fff !important;
	    font-style: italic;
	    background-color: #56b4b9;
    }
    input[type=text]{
	    border: 0px;
	    padding: 5px 10px;
	    color: #5f5e6d;
	    float: left;
    	display: block;
    }
}

/*** Scroll Bar ***/
::-webkit-scrollbar{
	width:8px;
	height:7px;
}
::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment{
	display:block;
	height:0; 
	background-color:transparent;
}
::-webkit-scrollbar-track-piece{
	background-color: #1c2023;
	@include border-radius(0px);
}
::-webkit-scrollbar-thumb{
	background-color: $themeColor;
	@include border-radius(0px); 
}
::-webkit-scrollbar-thumb:vertical{
}
::-webkit-scrollbar-thumb:horizontal{
}
::-webkit-scrollbar-thumb:hover{
	background-color: $themeColor;
}


.TopLevel::-webkit-scrollbar{
	width:4px;
	height:7px;
}
.TopLevel::-webkit-scrollbar-button:start:decrement,
.TopLevel::-webkit-scrollbar-button:end:increment{
	display:block;
	height:0; 
	background-color:transparent;
}
.TopLevel::-webkit-scrollbar-track-piece{
	background-color: #9c1816;
	@include border-radius(0px);
}
.TopLevel::-webkit-scrollbar-thumb{
	background-color: #ca1916;
	@include border-radius(0px); 
}
.TopLevel::-webkit-scrollbar-thumb:vertical{
}
.TopLevel::-webkit-scrollbar-thumb:horizontal{
}
.TopLevel::-webkit-scrollbar-thumb:hover{
	background-color: #ca1916;
}

.db{
	display: block;
}

fieldset{
	border: none;
}

/*** Search Form ***/


/*** HomePage ***/
.banner{
	position: relative;
	overflow: hidden;
	float: left;
	width: 100%;
	height: 100vh;
	@include background-cover(url("../images/img5.jpg"));

	.darkenOne{
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
		background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
		position: absolute;
		bottom: 0px;
		left: 0px;
		width: 100%;
		height: 50%;
		opacity: 0.3;
	}
	.darkenTwo{
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
background: -moz-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=1 ); /* IE6-9 */
		position: absolute;
		top: 0px;
		right: 0px;
		width: 50%;
		height: 100%;
		opacity: 0.3;
	}

	.bg{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		background-position: center;
		background-size: cover;
/*		@include background-cover(url("../images/bg.jpg")); */
	}
}

.bannerBg{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-position: center;
	background-size: cover;
/*	@include background-cover(url("../images/bg.jpg")); */
}


#google_translate_element{
    border: 30px solid #312d5e;
}

.searchToggle{
	cursor: pointer;
	z-index: 8000;
	*{
		color: #fff;
	}
	position: fixed;
	top: 5px;
	left: 10px;
	span{
	display: inline-block;
	margin-left: 10px;
	}
}


/*** Page ***/
.Page{
	.banner{
		height: 50vh;
	}
}
/*** Footer ***/
footer{
	padding: 30px 0px 0px 0px;
}


/*** Menu ***/
#Menu{
	float: left;
    z-index: 9999;
    width: 100%;
    text-align: center;
	display: inline-block;
	text-align: right;
	*{
		color: #000;
	}
	/*
	.MenuButton{
		display: inline-block;
		position: absolute;
		z-index: 2;
		cursor: pointer;
		background-color: #73a8d9;
	    right: 0px;
	    padding: 8px 12px 7px 12px;
	    cursor: pointer;
	    opacity: 1;
	    top: 0px;
	    text-transform: uppercase;
	}
	*/
	.MenuContainer{
    	text-align: center;
		position: relative;
		.LevelOne{
			display: inline-block; 
			text-align: center;
			position: relative;
			a{    
				font-size: 1em;
				@include transition($t1);
				padding: 5px 20px 10px 20px;
			    font-size: 1.1em;
			    line-height: 3em;
			    display: inline-block; 
	   	 		line-height: 1.1em; 
			}
		}
	}


	.SubMenu{
		@include box-shadow(-2px 13px 40px 0px rgba(47, 47, 47, 0.33));
		width: 250px;
		height: 0px;
		font-size: 0.7em;
		overflow: hidden;
		display: block;
		z-index: -9999;
    	left: 50%;
    	text-align: left;
		position: absolute;
	    background-color: $themeColor;
	    border-radius: 0px;
	    margin-top: 0px;
	   /* padding: 3px 10px 6px 10px;*/
	    opacity: 0;
	    transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -o-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -ms-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -moz-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -webkit-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    @include transform(translateY(20px) translateX(-50%) scale(0.98));

	    a{
		    display: inline-block;
		    padding: 10px 20px !important;
		    /*font-size: 0.9em;*/
		    line-height: 1.2em !important;
		    width: 100%;
		    font-weight: bold;
		    color: #fff !important;
			background-color: transparent;
	    	@include transition($t1);
			&:hover{
    			background-color: rgba(255,255,255,0.2) !important;
			}
		}
	}
	.menuItem-1,
	.menuItem-2{
		.SubMenu{
			left: 0%;
			@include transform(translateY(20px) translateX(0%) scale(0.98));
		}
	}
	.menuItem-Last{
		.SubMenu{
			left: auto;
			right: 0%;
			@include transform(translateY(20px) translateX(0%) scale(0.98));
		}
	}
	.active .SubMenu{
		opacity: 1 !important;
	 	@include transform(translateY(0px) translateX(-50%) scale(1) !important);
	}
	.menuItem-1.active .SubMenu,
	.menuItem-2.active .SubMenu{
		opacity: 1 !important;
	 	@include transform(translateY(0px) translateX(0%) scale(1) !important);
	}
	.menuItem-Last.active .SubMenu{
			opacity: 1 !important;
			left: auto;
			right: 0%;
			@include transform(translateY(0px) translateX(0%) scale(1) !important);	
	}

	/*.SubMenu:before {
	    content: '';
	    display: block;
	    position: absolute;
	    width: 100%;
	    height: 17px;
	    left: 0px;
	    top: -14px;
	    z-index: 2;
	}
	.SubMenu:after {
	    content: '';
	    display: block;
	    position: absolute;
	    border: 10px solid transparent;
	    top: -20px;
	    left: 50%;
	    margin-left: -10px;
	    border-bottom: 10px solid #2f3b67;
	}*/
}

.flexslider .flex-direction-nav {
    position: absolute;
    bottom: 16%;
    left: 50%;
    height: 30px;  
    z-index: 800;
    width: calc(100% - 80px);
    @include transform($center);
}
.flexslider .slides li {
    position: relative;
    height: 100vh;
}
.quoteBox {
    position: absolute;
    top: 50%;
    left: 150px;
    @include transform(translate(0%, -50%));
    z-index: 2;
    color: #fff;
    font-size: 2em;
    display: block;
    opacity: 1;
    line-height: 1.2em;
    text-transform: uppercase;
    *{
    	color: #fff !important;
    }
}

.slideBG{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-position: center;
	background-size: cover;
	background-image: url(../images/bg.jpg);
}
/* -------------------- General Site layouts -------------------- **/




/* -------------------- Site Build -------------------- **/
#header{
	position: fixed;
	background-color: rgba(255,255,255,0.8);
	z-index: 9998;
	border-bottom: 3px solid $themeColor;
	top: 0px;
	left: 0px;

	@include transform( translateY(0px));
	width: 100%;
	text-align: center;
	@include transition($t1);
	.MainLogo{
		margin-top: 30px;
		font-size: 3em;
		opacity: 1;
		@include transition($t1);
			color: $themeColor;
		display: inline-block;
	}
}

.homebtn{
	position: absolute;
	top: 20px;
		opacity: 1;
	left: 100px;
	@include transition($t1);
	img{
		width: 100px;
	}
}

.twitterBanner{
	position: fixed;
	bottom: 0px;
	width: 400px;
	opacity: 1;
	@include transform( translateX(0%));
	height: calc(100% - 141px);
	@include transition($t1);
	background-color: #e4f4fd;
	z-index: 99; 
	.bg{
		background-size: cover;
		background-position: center;
		opacity: 0.2;
		height: 100%;
		width: 100%;
		background-image: url(../images/twitterbg.jpg);
	}
}

.twitterInner{
	position: absolute;
	top: 50%;
	left: 50%;
	padding: 20px;
	width: 100%;
	@include transform($center);
	.innerTweet{
		padding: 20px 0px;
	}
}
.tweetDate{
	font-weight: bold;
}
.tweetText{
}

.twitterLink{
	border: 1px solid #161f38;
	text-transform: uppercase;
	color: $themeColor;
	padding: 10px;
	font-size: 0.8em;
	margin-top: 15px;
	text-align: center;
	display: inline-block;
	@include transition($t1);
	&:hover{
		color: #fff;
		background-color: #161f38;
	}
}

.TwitterHeading{
	padding: 35px 0px;
	border-bottom: 1px solid $themeColor;
	font-weight: normal;
	font-size: 2em;
}

.scrollNav{
	.MainLogo,
	.homebtn{
		opacity: 0;
	}

	.twitterBanner{
		opacity: 0;
		@include transform( translateX(-100%));
	}
	#header{

	@include transform( translateY(-106px));
	}
	#Menu .MenuContainer .LevelOne a{
		padding-top: 20px;
		padding-bottom: 20px;
	}
}

.twitterNext{
	position: absolute;
	width: 40px;
	height: 30px;
	right: 0px; 
	top: 44px;
	background-image: url(../images/twitterNext.png);
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	cursor: pointer;
	a{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
	}
}

.bannerText{
	position: absolute;
	right: 0px;
	padding: 20px;
	bottom: 40px;
	font-size: 5em;
	z-index: 98;
	text-anchor: right;
	img{
		display: inline-block;
		height: 40px;
		    margin-bottom: 30px;
	}
	*{
		color: #fff;
	}
}
#Home2{
	padding: 50px 20px;
	text-align: center;
	.inner{
		width: 1200px;
	}
}
.imgIcon{
	padding-bottom: 32%;
	img{
		-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
		position: absolute;
		top: 50%;
		left: 50%;
		opacity: 0.3;
		height: 60%;
		@include transition($t1);
		@include transform($center);
	}
}
.quicklink{
	.quicklinkTitle{
		padding: 10px;
		font-size: 1.6em;
		color: #172037;
	}
	&:hover{
		img{
			opacity: 1;
			-webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
    		filter: grayscale(0%);
		}
	}
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
	&:after{
		height: 80%;
		width: 2px;
		top: 50%;
		position: absolute;
		background-color: $themeColor;
		content: '';
		display: block;
		right: 0px;
		@include transform(translateY(-50%));
	}
}
.quicklink:last-of-type{
	&:after{
		display: none !important;
	}
}
#Home3{
	background-color: #e8f5fb;
	padding: 80px 20px;
	.inner{
		width: 1200px;
	}
	.pageTitle{
		color: #161f38;
	}
	*{
		color: #161f38;
	}
	.overlay1{
		position: absolute;
		display: block;
		right: 5%;
		height: 80%;
		bottom: 0px;
		opacity: 0.2;
	}
}
.contentSection{
	font-weight: lighter;
	font-size: 1.1em;
}
.WelcomeImage{
	background-image: url(../images/bg.jpg);
	background-size: cover;
	background-position: center;
	padding-bottom: 100%;
}
.topLinks{
	position: absolute;
	top: 0px;
	right: 5%;
	img{
		height: 40px;
	}
	a{
		margin: 0px 10px;
		display: inline-block;
	}
}
#Home4{
	padding: 100px 0px;
	text-align: center;
	.pageTitle{
		color: #161f38;
	}
}


/*** Percentacge housepoints ***/
.svg circle {
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 1.5s linear;
  stroke: #e8f5fb;
  stroke-width: 1px;
}
.svg {
  stroke-width: 6px;
}
.svg .bar{
  stroke-width: 6px;
  stroke: $themeColor;
}

.Jade .bar{
  stroke: #37ba8e;
}
.Topaz .bar{
  stroke: #34b0e3;
}

.Zircon .bar{
  stroke: #efefef;
}

.Citrine .bar{
  stroke: #fdd224;
}
 
.Amethyst .bar{
  stroke: #96c;
}

.Garnet .bar{
  stroke: #74010b;
}



#cont1,
#cont2,
#cont3,
#cont4,
#cont5 {
  display: block;
  height: 200px;
  width: 200px;
  margin: 2em auto;
  border-radius: 100%;
  position: relative;
}
.housePointTitle{
	font-weight: lighter;
	color: #000;
	font-size: 1.5em;
	padding: 20px 0px;
	&:before{
		content:'';
		position: absolute;
		bottom: -10px;
		left: 50%;
		width: 20%;
		height: 2px;
		display: block;
		background-color: #87caed;
		@include transform(translateX(-50%));
	}
}

.HousePointsCount{
	font-weight: lighter;
	color: #000;
	font-size: 3em;
	position: absolute;
	top: 50%;
	left: 50%;
	@include transform($center);
}
.footerInner{	
	border-top: 3px solid $themeColor;
	padding: 20px;
	i{
	color: $themeColor;
	margin: 0px 8px;
	}
	.footerLinks{
		text-align:right;
		a{
			display: inline-block;
			padding: 10px;
			font-size: 0.9em;
			@include transition($t1);
			&:hover{
				color: $themeColor !important;
			}
		}
	}
	span{
		display: inline-block;
		padding-left: 10px;
	}
}
.footerBottom{
	padding: 20px 20px;
	font-size: 0.8em;
	background: #161f38;
	*{
		font-size: 1em;
		color: #fff;
	}
}
.footerLogo{
	a{
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0px;
		top: 0px;
	}
	img{
		margin-right: 10px;
		width: 100px;
	}	
	.logoTitle{
		color: $themeColor;
		font-size: 2.4em;
	}
}
.dib{
	position: relative;
	vertical-align: middle;
	display: inline-block;
}
.fse{
	margin-bottom: 0px !important; 
}
.footerRight{
	padding-top: 40px;
}
.FooterAddress{
	font-size: 0.8em;
	padding-top: 10px;
}
.Decoration{
	height: 1px;
	background-color: $themeColor; 
}

#__toolbox-opener{
	z-index: 99999;
}

.PageImage{
	background-size: cover;
	background-position: center;
	background-image: url(../images/bg.jpg);
	padding-bottom: 15%;
}
#breadcrumbs{
	background-color: $themeColor;
	padding: 10px;
	margin-top: 138px;
	font-size: 1em;
	*{
		font-size: 1em;
		color: #fff !important;
	}
}
.sideBarContainer{
	background-color: #e8f5fb;
}
.sideBarLeft{
	    background-color: #e8f5fb;
	    position: absolute;
	    top: 0px;
	    height: 100%;
	    left: 0px;
}

.innerContent{
	width: 100%;
	max-width: 900px;
	margin: 0 auto;
}

.InnerPageTitle{
	border-bottom: 1px solid $themeColor;
	padding-bottom: 20px;
	margin-bottom: 20px;
	color: #000;
	font-size: 2.5em;
	font-weight: 400;
	margin-top: 40px;
}
.InnerPage{
	#header{
		background-color: #fff !important;
	}
}

.FileUpload{
	border: 1px solid $themeColor;
	padding: 10px 50px 10px 20px;
	position: relative;
	color: $themeColor;
	display: block;
	width: 100%;
	max-width: 500px;
	clear: both;
	margin-bottom: 10px;
	float: left;
	@include transition($t1);
	&:hover{
		background-color: #eee !important;
	}
	img{
		position: absolute;
		bottom: 0px;
		height: 90%;
		right: 10px;
	}
}


.pageList{
	a{
		display: block;
		margin-bottom: 5px;
		background-color: #eee;
		padding: 10px 20px;
		color: #475f79 !important;
		@include transition($t1);
		&:hover{
			background-color: #a70051 !important;
			color: #fff !important;
		} 
	}
}

#MemberLoginForm_LoginForm{
	max-width: 500px; 
}

.error{
    display: inline-block;
    float: left;
    clear: both;
    padding: 10px 20px;
    margin-bottom: 20px;
    border: 1px solid #c70000;
    color: #c70000;
    background-color: #ffe7e7;
}
label {
    display: block;
    float: left;
    clear: both;
    width: 100%;
}






.CalendarFilter{
	padding: 10px;
}

/* .CalendarFilter{
	span{
		display: inline-block;
		padding: 5px 0px;
		margin: 0px 5px;
		cursor: pointer;
	}
} */
.CalendarEvent #allEvents ul li,
.Calendar #allEvents ul li{
/* 	display: none; */
}
/* .CalendarEvent.All #allEvents ul li,
.Calendar.All #allEvents ul li{
	display: block;
} */
.CalendarFilter span{
	cursor: pointer;
	display: inline-block;
	padding: 5px;
	border-bottom: 2px solid transparent;
	margin-bottom: 10px;
}
.CalendarFilter span.active{
	border-bottom: 2px solid $themeColor !important;
	color: $themeColor !important;
}
.noEvents{
	display: none;
	padding: 20px 10px;
	color: #666;
	@include border-radius(3px);
    background-color: #eee;
}

#allEvents ul{
	max-width: 500px;
	margin: 0px !important;
	padding: 0px !important;
}

#allEvents ul li{
	margin-bottom: 10px;
	background-color: $themeColor;
	float: left;
    width: 100%;
    clear: both;
    @include transition(background 0.35s);
    display: block;
}






.toggleContent{
	display: none;
	font-size: 0.8em;
	*{
		font-size: 1em;
		color: #333 !important;
	}
	.url{
		padding: 10px;
    	color: #fff !important;
    	background-color: $themeColor;
    	@include transition($t1);
    	@include border-radius(3px);
    	/*&:hover{
    		background-color: #5a5962 !important;
    		color: #fff !important;
    	}*/
	}
}


.eventTitle{
	color: #333 !important;
	padding: 10px;
	text-transform: uppercase;
}
.toggleBtn{
	cursor: pointer;
	sup{
	display: none !important;
	}
}

.calendar-widget-table tbody .calendar-header td{
	
	    background-color: $themeColor;
}


/*
|===============================================================
|	Calendar Page Template Widget
|===============================================================
*/
.calendar-widget-table {
	width: 100%;
	background-color: #fff;
	margin:0 0 2em;
	*{
		text-align: center;
	}
	thead {
		tr {
			th {
				border: none;
    			padding: 15px 20px;
				background-color: #475f79;
				    font-size: 1em;
				.show-month, .next, .prev {
					display:inline-block;
					color: #FFFFFF;
					font-weight: bold;
					font-weight: lighter;
    				font-size: 1.3em;
					i {
						color: #fff;
					}
					.inner {
						color: #FFFFFF;
						width: auto;
					}
				}
				.show-month {
					padding: 0 20px;
				}
			}
		}
	}
	.calendar-header {
		td.calendar-header-day, td {
			background: transparent !important;
			color: #475f79;
			border:none !important;
		}
	}
	tbody{
		.calendar-day {
			border: 1px solid #ececec;
			color: #a9afb9;
			@include transition($t1);
		}
		tr {
			&:nth-child(even){ 
				background-color:transparent; 
			}
			td {
				padding: 10px 0px !important;
				z-index: 1;
				text-align: center !important;
				.calEventPopup {
					position: absolute;
					z-index: 80 !important;
					padding:20px;
					border:2px solid $LinkColor;
					background-color: #fff;
					    width: 180px;
					    text-align: center;
					    display:none;
					.dayTitle {
						color:$LinkColor;
						margin-bottom:20px;
						font-weight:500;
					}
					.eventLine {
						color:$LinkColor;
					}

				}
				&.hasEvent:hover {
					.calEventPopup {
						display: block;
						z-index: 90 !important;
					}
				}
				&:nth-child(1) .calEventPopup { margin-left:0; }
				&:nth-child(2) .calEventPopup { margin-left:-20px; }
				&:nth-child(3) .calEventPopup { margin-left:-40px; }
				&:nth-child(4) .calEventPopup { margin-left:50%; @include transform(translateX(-50%)); }
				&:nth-child(5) .calEventPopup { margin-right:-40px; }
				&:nth-child(6) .calEventPopup { margin-right:-20px; }
				&:nth-child(7) .calEventPopup { margin-right:0px; }
			}
		}

		.today {
			color: #676b71;
		}
		
		.selected{color: #fff !important;}
		.out-of-month {
			background-color:transparent !important;
			color: #efefef;
		}

		.hasEvent {
			background-color: $themeColor !important;
			background-repeat: no-repeat;
			background-position: 5px 5px;
			background-size: 4px 4px;
			color: #fff !important;
			&:hover{
			
			background-color: $themeColor !important;

			color: #fff !important;
			}

		}
	} 
	.calendar-day:hover, .selected {
		
			background-color: $themeColor !important;

			color: #fff !important;
		font-weight: 500;
	}
}

.calendarWidget{
    padding: 10px;
}

/*
|===============================================================
|	Calendar Filter
|===============================================================
*/

.CalendarFilter{
    padding: 10px;
}

/* .CalendarFilter{
    span{
        display: inline-block;
        padding: 5px 0px;
        margin: 0px 5px;
        cursor: pointer;
    }
} */
.CalendarEvent #allEvents ul li,
.Calendar #allEvents ul li{
/*     display: none; */
}
/* .CalendarEvent.All #allEvents ul li,
.Calendar.All #allEvents ul li{
    display: block;
} */
.CalendarFilter span{
    cursor: pointer;
    display: inline-block;
    padding: 5px;
    color: #333 !important;
    border-bottom: 2px solid transparent;
    margin-bottom: 10px;
}
.CalendarFilter span.active{
    border-bottom: 2px solid $themeColor !important;
    color: $themeColor !important;
}
.noEvents{
    max-width: 500px;
    display: none;
    padding: 20px 10px;
    color: #666 !important;
    @include border-radius(3px);
    background-color: #eee;
}

#allEvents ul{
    max-width: 500px;
    margin: 0px !important;
    padding: 0px !important;
}
#allEvents ul li,
#HomepageEvents ul li{
    @include border-radius(5px);
    margin-bottom: 10px;
    background-color: $themeColor ;
    float: left;
    width: 100%;
    clear: both;
    margin-left: 0;
	span.suffix {
		font-size: 12px;
		vertical-align: top;
		margin-left: 2px;
		line-height: 6px;
	}
    @include transition(background 0.35s);
    display: block;
    
    .toggleBtn{
        cursor: pointer;
        padding: 10px;
        padding-left: 20px;
        color: #fff;
        *{
			color: #fff;
        }
		.arrow {
			position: absolute;
			top: 15px;
			right: 15px;
			color: #fff;

		    -webkit-transition-duration: 0.8s;
			-moz-transition-duration: 0.8s;
			-o-transition-duration: 0.8s;
			transition-duration: 0.8s;

			-webkit-transition-property: -webkit-transform;
			-moz-transition-property: -moz-transform;
			-o-transition-property: -o-transform;
			 transition-property: transform;
		}
		&.active {
			.arrow {
				@include transform(rotate(180deg));
			}
		}
    }
}

.toggleContent{
	display: none;
	font-size: 0.8em;
	*{
		font-size: 1em;
		color: #fff !important;
	}
	.url{
		padding: 10px;
    	color: #333 !important;
    	background-color: #6d153f;
    	@include transition($t1);
    	&:hover{
    		background-color: #4d4d4d !important;
    		color: #fff !important;
    	}
	}
}
.toggleBtn{
	cursor: pointer;
}

.calendar-widget-table thead tr th {
    padding: 10px 20px 10px 20px;
}

.calendar-widget-table thead tr th .show-month{
	font-size: 1.2em !important;
}

.calendar-widget-table thead tr th{
	    background-color: $themeColor !important;
}
#allEvents ul li, #HomepageEvents ul li{
	    background-color: $themeColor !important;
	    *{
	    color: #fff !important;
	    }
}
#HomepageEvents ul li,
#allEvents ul li{
	position: relative;
}
#allEvents ul li .toggleBtn, #HomepageEvents ul li .toggleBtn {
 	padding: 0px !important;
    float: left;
    width: 100%;
}

.calday{
	position: absolute;
    top: 10px;
    left: 19px;
}
.eventTitle{
	padding-left: 110px;
	padding-right: 35px;
	width: 100% !important;
}
.calendar-widget-table tbody .hasEvent,
.hasEvent{
	background-image: none  !important;
}
/* -------------------- Site Build -------------------- **/

.fancybox-overlay {
    z-index: 9999;
}

.up{
	position: absolute;
	top: 50%;
	right: 20px;
	height: 25px;
	cursor: pointer;
	@include transform(translateY(-50%));
}


#SideNav{
	position: fixed;
	top: 30%;
	right: 50px;
	@include transform(translateY(-50%));
	z-index: 5996;

	img{
		width: 14px;
		margin-right: 4px;
		vertical-align: middle;
		display: inline-block;	
	}

	.slink{
		color: #000;
		position: relative;
		margin-bottom: 10px;
	}
	.dot{
		position: absolute;
		top: 50%;
		right: 0px;
		@include transform(translateY(-50%));
		display: inline-block;
		width: 13px;
		height: 13px;
		margin-right: 5px;
		@include border-radius(50%);
		@include transition($t1);
		background-color: #c2c2c1;
		vertical-align: middle;
	}
	.sectionTitle{
		@include transition($t1);
		font-size: 1em;
		    font-weight: bold;
		vertical-align: middle;
		display: inline-block;
		color: #999 !important;
		margin-top: 2px;
	}
	.slink{
		padding: 0px 5px;
		padding-right: 30px;
		cursor: pointer;

		&:hover{
			.sectionTitle{
				color: #999 !important;
				opacity:  1 !important;
			}

			.dot{ 
				background-color: #62b9e5 !important;
			}
		}
	}
	.slink.active{
		
		.sectionTitle{
			color: #999 !important;
			opacity: 1;
		}
		.dot{ 
			background-color: #62b9e5 !important;
		}
	}
}

.parentMail{
	display: inline-block;
	padding: 0px 20px;
	vertical-align: top;
	background-color: #fff;
	@include transition($t1);
	img{
		display: block;
	}
	&:hover{
		padding: 10px 20px 0px 20px;
	}
}

.homework{
	background-color: #172037;
	padding: 0px 0px;
	display: inline-block;
	vertical-align: top;
	@include transition($t1);
	img{
		display: block;
	}
	&:hover{
		padding: 10px 0px 0px 0px;
	}
}
.flexslider .flex-direction-nav {
display: none;
}


.SixthForm #header {
    background-color: rgba(255,255,255,0.8) !important;
}
.SixthForm .banner{
	position: relative;
	overflow: hidden;
	float: left;
	width: 100%;
	height: 100vh;
	@include background-cover(url("../images/img5.jpg"));

	.darkenOne{
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
		background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
		position: absolute;
		bottom: 0px;
		left: 0px;
		width: 100%;
		height: 50%;
		opacity: 0.3;
	}
	.darkenTwo{
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
background: -moz-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=1 ); /* IE6-9 */
		position: absolute;
		top: 0px;
		right: 0px;
		width: 50%;
		height: 100%;
		opacity: 0.3;
	}

	.bg{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		@include background-cover(url("../images/bg.jpg"));
	}
}

.bannerBg{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	@include background-cover(url("../images/bg.jpg"));
}

.policyBar{
	padding: 10px;
	font-size: 0.7em;
	background-color: #2b2b2b;
	color: #fff;
	*{
	color: #fff;
	}
}
.cookieWarning {
    width: 100% !important;
}


.InnerPage{
	#HousePointContainer{
	.col-2{
		width: 33.33%;
	}
	}
}
/* -------------------- Site Build -------------------- **/