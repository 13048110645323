/*** Mobile Screens ***/
#sidebar{
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
  float: right;
  position: relative;

  .Level-1{
    display: none;
    ul{
      clear: both;
      float: left;
      width: 100%;
    }
  }

  button{
    position: absolute;
    top: 0px;
    border: none;
    outline: none !important;
    right: 0px;
    cursor: pointer;
    width: 30px;
    height: 28px;
    @include transition($t1);
    background-color: transparent;
    font-size: 1.3em;
    color: $textDark;
    &:hover{
      color: $themeColor;
    }
  }
  .sidebar-heading{
    display: block;
    float: left;
    clear: both;
    width: 80%;
    padding-bottom: 20px;
    a{
      padding: 5px 30px 5px 10px;
      display: block;
      text-decoration: none;
      color: $themeColor;
      font-size: 2em;
    }
  }
  .Level-1{
  padding-left: 10px;
  }
  ul{
    ul{
      display: none;

      li{
        a{
          /*** Level Indentation ***/
          margin-left: 20px !important;
        }
      }

      ul{
        li{
          a{
            /*** Level Indentation ***/
            margin-left: 30px !important;
          }
        }
      }
    }
    li{
      clear: both;
      float: left;
      width: 100%;
      position: relative;
      a{
        display: block;
        padding: 5px 30px 5px 0px;
        text-decoration: none;
        @include transition($t1);

        position: relative;

        &:after{
          content: '';
          height: 2px;
          left: 0px;

          position: absolute;
          display: block;
          width: 0%;
          background-color: $themeColor;
          @include transition($t1);
        }

        span{
          color: $textDark;
          @include transition($t1);
        }
        &:hover span{
          color: $themeColor !important;
        }
        &:hover{
          &:after{
            width: 100%;
          }
        }
      }
    }
  }
}

#sidebar-inner{
  overflow: hidden;
  float: left;
  clear: both;
  width: 100%;
}

/*** Desktop Screens ***/
@media all and (min-width: 768px) {
  #sidebar{
    .Level-1{
      display: block !important;
      margin-top: 0% !important;
    }
    ul{
      ul{
        display: none;
      }
    }
    .mainToggle:first-of-type{
      display: none  !important;
    }
  }
  .hideSideBarToggle{
    display: none;
  }
}
